<template>
	<!-- 图片预览 -->
	<el-dialog :visible.sync="previewStatus" width="50vw" top="5vh">
		<div style="margin-top: -60px;margin-left: -20px;margin-right: -20px;margin-bottom: -30px;">
			<img :src="`${previewUrl}?imageView2/2/w/600/q/95`" class="w-100">
		</div>
	</el-dialog>
</template>

<script>
export default {
		props:{
			previewUrl:String,
			previewModel:{
				type:Boolean,
				default:false
			},
		},
		computed: {
			  previewStatus: {
				get() {
				  return this.previewModel;
				},
				set(val) {
				  //previewModel改变由父组件控制
				  this.$emit("changePreview", val);
				}
			  }
		},
	}
</script>

<style>
</style>

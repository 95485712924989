<template>

	  <li class="w-100 list-group-item list-group-item-action flex align-center border-bottom py-1 bx"  @click="$emit('itemChange',index)" :class="{'sum-active':active}" style="cursor: pointer;">
		  {{item.name}}
		  <span class="btn btn-light btn-sm border ml-auto" v-if="!showDropdown">
		    {{item.num}}<i class="el-icon-arrow-down el-icon--right"></i>
		  </span>
		  <el-dropdown class="ml-auto" v-else>
		    <span class="btn btn-light btn-sm border">
		      {{item.num}}<i class="el-icon-arrow-down el-icon--right"></i>
		    </span>
		    <el-dropdown-menu slot="dropdown">
		      <el-dropdown-item @click.stop.native="$emit('albumModelChange',{item,index})">修改</el-dropdown-item>
		      <el-dropdown-item @click.stop.native="$emit('albumDelChange',index)">删除</el-dropdown-item>
		    </el-dropdown-menu>
		  </el-dropdown>
	  </li>

</template>

<script>
	export default {
		props:{
			item:Object,
			index:Number,
			active:{
				type:Boolean,
				default:false
			},
			showDropdown:{
				type:Boolean,
				default:true
			},
		},
		methods:{

		}
	}
</script>

<style>
  .list-group-item {
      position: relative;
      display: block;
      padding: 10px 20px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.125);
  }
  .list-group-item-action {
      width: 100%;
      color: #495057;
      text-align: inherit;
  }
  .sum-active {
      color: #67c23a!important;
      background-color: #f0f9eb!important;
      border-color: #c2e7b0!important;
  }
</style>

export default {
	state:{
       menus:[],
       routers:[],
	},
	getters:{
       menus:state => state.menus,
       routers:state => state.routers,
	},
	mutations:{
       createMenus(state,menus){
		   state.menus = menus
		   let list = []
		   menus.map((item)=>{
	           if(item.sub.length != 0){
				   item.sub.map((child)=>{
					   list.push({
							path: child.path,
							component: `() =>import('../views${child.path}${child.path}.vue')`,
							name: child.pathname.charAt(0).toUpperCase()+child.pathname.slice(1),
							meta: {
							   title: child.title
							},
							children: null
					   })
				   })
			   }else{
				   list.push({
						path: item.path,
						component: `() =>import('../views${item.path}${item.path}.vue')`,
						name: item.pathname.charAt(0).toUpperCase()+item.pathname.slice(1),
						meta: {
						   title: item.title
						},
						children: null
				   })
			   }
		   }) 
		   state.routers = list
		   window.sessionStorage.setItem('navBar',JSON.stringify(list))
	   },
	   //初始化信息
	   initMenus(state){
	   	let navBar = window.sessionStorage.getItem('navBar') || ''
	   	if(navBar){
	   		state.navBar = JSON.parse(navBar)
	   	}
	   },
	},
	actions:{
		
	}
}
export default {
	state:{
		aside:true,
		bg_color:'#545c64',
		mainColors:[
			{name:'主色',color:'#545c64'},
			{name:'黑色',color:'#393D49'},
			{name:'蓝色',color:'#0085E8'},
			{name:'绿色',color:'#2F9688'},
			{name:'紫红',color:'#50314F'},
			{name:'棕红',color:'#AA3130'},
		],
		config:{},// 店铺配置信息
	},
	getters:{
		config: state => state.config,
	},
	mutations:{
       changeBgColor(state,val){
       	 state.bg_color = val
		 window.localStorage.setItem('bg_color',val)
       },
	   //初始化信息
	   initCommon(state){
			let bg_color = window.localStorage.getItem('bg_color') || ''
			if(bg_color){
				state.bg_color = bg_color
			}
	   },
	   config (state, val) {
	   	  state.config = val
	   },
	},
	actions:{

	}
}
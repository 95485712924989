import $Util from "@/common/util.js"
export default {
	state:{
		skus_type:1,//1单规格2多规格
		title: '',
		category: [],
		desc:'',
		unit:'',
		stock:0,
		min_stock:0,
		dispaly_stock:1,
		status:1,
		express:'',
		images:[
			// {
			// 	id:1,
			// 	url:'https://tangzhe123-com.oss-cn-shenzhen.aliyuncs.com/Appstatic/qsbk/demo/datapic/40.jpg'
			// }
		],
		//单规格
		price:0,//销售价
		mktprice:0,//市场价
		costprice:0,//成本价
		weight:0,//重量
		volume:0,//体积
		//多规格
		sku_card:[
			// {
			// 	name:'颜色',
			// 	type:1,//1无 2眼色 3图片
			// 	list:[
			// 	{
			// 		name:'属性名称',
			// 		image:'',
			// 		color:'',
			// 	}
			// ],
			// },
		],
		ths:[
			{name:"商品规格",rowspan:1,colspan:1,width:''},
			{name:"sku图片",rowspan:2,width:'60'},
			{name:"销售价",rowspan:2,width:'100'},
			{name:"市场价",rowspan:2,width:'100'},
			{name:"成本价",rowspan:2,width:'100'},
			{name:"库存",rowspan:2,width:'100'},
			{name:"体积",rowspan:2,width:'100'},
			{name:"重量",rowspan:2,width:'100'},
			{name:"编码",rowspan:2,width:'100'},
		],
		//商品类型
		goods_type_id:0,
		goods_attrs:{
			mobile:''
		},
		discount:'',//商品折扣
	},
	getters:{
		skuLabels(state){
			return state.sku_card.filter(v=>{
				return v.list.length > 0
			})
		},
		//获取表头
		tableThs(state,getters){
			let length = getters.skuLabels.length
			state.ths[0].colspan = length
			state.ths[0].rowspan = (length > 0) ? 1 : 2
			return state.ths;
		},
		//获取表格数据
		tableData(state){
			if(state.sku_card.length == 0){
				return []
			}
			let sku_list = []
			for (var i = 0; i < state.sku_card.length; i++) {
				if(state.sku_card[i].list.length > 0){
					sku_list.push(state.sku_card[i].list)
				}
			}
			if(sku_list.length === 0) return []
			let arr = $Util.cartesianProductOf(...sku_list)
			return arr.map(v=>{
				let obj =  {
					skus:[],
					image:'',
					price:0,
					mktprice:0,
					costprice:0,
					stock:0,
					volume:0,
					weight:0,
					bn:'编码',
				}
				obj.skus = v
				return obj
			})
		},
		
	},
	mutations:{
		//修改state
		eState(state,{key,val}){
			state[key] = val
		},
		addSkuCard(state){
			state.sku_card.push({
				name:'规格名称',
				type:1,//1无 2眼色 3图片
				list:[],
			})
		},
		delSkuCard(state,index){
			state.sku_card.splice(index,1)
		},
		editSkuCard(state,{key,index,val}){
			state.sku_card[index][key] = val
		},
		sortSkuCard(state,{action,index}){
			$Util[action](state.sku_card,index)
		},
		//增加规格属性
		addSkuAttrs(state,index){
			state.sku_card[index].list.push({
				name:'属性名称',
				image:'',
				color:'',
			})
		},
		//删除规格属性
		delSkuAttr(state,{cardIndex,valIndex}){
			state.sku_card[cardIndex].list.splice(valIndex,1)
		},
		//修改规格属性
        editSkuAttr(state,{cardIndex,valIndex,key,val}){
			state.sku_card[cardIndex].list[valIndex][key] = val
		},
		//拖拽属性排序
		sortSkuAttr(state,{index,val}){
			state.sku_card[index].list = val
		},
		//修改商品属性
		editGoodsAttrs(state,{key,val}){
			state.goods_attrs[key] = val
		},
	},
	actions:{
		
	}
}
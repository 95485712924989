import Vue from 'vue'
import Vuex from 'vuex'

import common from "./modules/common.js"
import goods_create from "./modules/goods_create.js"
import user from "./modules/user.js"
import menu from "./modules/menu.js"
import setting from "./modules/setting.js"

Vue.use(Vuex)

export default new Vuex.Store({
	modules:{
		common,
		goods_create,
		user,
		menu,
		setting,
	}
})
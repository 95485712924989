export default {
	state:{
		manage:{},
		token:false,
	},
	getters:{
		
	},
	mutations:{
		//初始化信息
		initUser(state){
			let manage = window.sessionStorage.getItem('manage') || ''
			if(manage){
				state.manage = JSON.parse(manage)
				state.token = state.manage.token
			}
		},
		login(state,manage){
		   //保存登录状态
		   state.manage = manage
		   state.token = manage.token
		   //存储本地
		   window.sessionStorage.setItem('manage',JSON.stringify(manage))
		   window.sessionStorage.setItem('token',manage.token)
		},
		logout(state){
			//清除登录状态
			state.manage ={}
			state.token = false
			//清除存储本地
			window.sessionStorage.clear()
		}
	},
	actions:{
		
	}
}